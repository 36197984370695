import Typewriter from "./typewriter.js";

// console.log("made it")

var app = document.getElementById('typewriter');

var typewriter = new Typewriter(app, {
    loop: false
});

typewriter.changeCursor('_').typeString('#!/bin/bash/ \n echo \"Welcome to myPa')
    .deleteChars(2)
    .typeString(" Page!\"")
    .pauseFor(500)
    .changeDelay(1)
    .changeCursor(' ').typeString('\n ><strong>Welcome to My Page!</strong>')
    // .changeCurors("|")
    
    .pauseFor(250)
    // .changeCurors("|")
    // .deleteAll(.0001)
    .start();
typewriter.changeCursor('_');

